import React from 'react'
import siteConfig from '../../../data/siteConfig'
import styled from 'styled-components'


const Education = ({ title = 'Education'}) => {
    return (
        <div>
            <h1>
                {title}
            </h1>
            {siteConfig.education && siteConfig.education.map(school => (
                <div>
                    <div style={{"display": "flex", "justify-content":"space-between"}}>
                        <h2 style={{"color": "#6495ED"}}>{school.name}</h2>
                        <p>{school.classYear}</p>
                    </div>

                    {school.degrees && school.degrees.map(degree => (
                        <div style={{"margin-left": "40px"}}>
                            <h3>{degree.title}</h3>
                            <p>Major: {degree.major}</p>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default (Education)

